/* -------------------------------------------------------------------------
Table of Contents
1. Navigation
2. Home
3. API
4. Code
------------------------------------------------------------------------- */



body {
  margin: 0 ;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* -------------------------------------------------------------------------
1. Navigation
------------------------------------------------------------------------- */
.AppBar{
  background-color: #DCE6E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 100vh;
  position: fixed;
  border-right: 1px solid #444;
  top:0;
  left:0;
  -webkit-box-shadow: 10px 0 5px -2px #888;
          box-shadow: 10px 0 5px -2px #888;
}
.AppBar .title-div{
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  text-align:center;
  font-size:35px;
  background-color: white;
  color:#0C2E82;
  width:200px;
  padding: 30px 0px;
}
.AppBar .link,
.AppBar .alink{
  display:inline-block;
  text-decoration:none;
  color:#0C2E82;
  font-size:25px;
  font-weight: 500;
  letter-spacing: 2px;
  padding:20px 0px;
  width:200px;
  text-align: center
}
.AppBar .link:hover,
.AppBar .alink:hover,
.AppBar .alink-active:hover{
  cursor: pointer;
  background-color: #87CFA3; /* rgba(255,255,255,0.3); */
}
.AppBar .active{
  text-decoration:none;
  width:200px;
  color:#0C2E82;
  background-color: white;
  border-right:1px solid black
}
.AppBar .alink-active{
  display:inline-block;
  color:#0C2E82;
  background-color: white;
  border-right: 1px solid black;
  font-size:25px;
  font-weight: 500;
  padding:20px 0px;
  width:200px;
  letter-spacing: 2px;
  text-align: center;
  text-decoration:none;
}
.AppBar .cocorahs{
  display:flex;
  align-items: center;
  justify-items: flex-end;
}
.AppBar .smooth{
  transition: height 1s, opacity 0.5s linear;
}

/* -------------------------------------------------------------------------
2. Home
------------------------------------------------------------------------- */
.Home{
  width:calc(100vw - 200px);
  padding-left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Home p{
  font-size:30px;
  width:80vw;
  text-align: center;
  line-height: 50px;
}
/* -------------------------------------------------------------------------
3. API
------------------------------------------------------------------------- */
.api{
  width:calc(100vw - 400px);
  padding-left: 200px;
  display: flex;
  flex-direction: column;
}
.api .sidebar{
  width:200px;
  margin-top:50px;
  position: fixed;
  top: 0;
  right: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.api .example{
  width:calc(100vw - 400px);
  display: flex;
  flex-direction: column;
}
.api .example-title{
 font-size: 40px;
 margin-left: 50px;
 font-weight: 600;
 letter-spacing: 2px;
}
.api .example-text{
  width: 90%;
  font-size: 23px;
  letter-spacing: .75px;
  margin:50px;
  margin-top:0px;
  line-height: 2;
}
.api .example-code{
  align-self: center;
  width:60vw;
}
.api .example-end{
  width: 90%;
  font-size: 23px;
  margin:50px;
  letter-spacing: .75px;
  line-height: 2;
}
.api .introduction-text{
  width: 90%;
  font-size: 25px;
  margin-left:50px;
  letter-spacing: .75px;
  line-height: 2;
}
.api .introduction{
  width: 90%;
  height: 80px;
  font-size: 80px;
  margin-left:50px;
  margin-top:20px;
  margin-bottom: 20px;
  font-weight: 500;
}
.api .introduction-divider{
  width: 45%;
  height: 2px;
  background: #444;
  margin-left: 50px;
  margin-bottom: 50px;
}
.select-css {
  display: block;
  align-self: center;
	font-size: 20px;
	font-weight: 700;
	color: #444;
  line-height: 2;
	width: 10vw;
	margin-bottom: 10px;
	border: 1px solid #aaa;
  border-radius: .5em;
}
.api .container{
  display: flex;
  flex-direction: column;
  width:100%;
}
.api .section1{
  background: red;
  height: 100vh;
  width:100%;
}
.api .section2{
  background: green;
  height: 100vh;
  width:100%;
}
.api-link{
  font-size: 22px;
  width:90%;
  text-decoration: none;
  color:gray;
  background: white;
  padding-left:15px;
  padding-bottom:10px; 
}
.api-link-div{
  width:100%;
  background:white;
  display: flex;
  justify-content: flex-end;
}
.api-link-div:hover{
  cursor: pointer;
  background: lightgray;
}
/* -------------------------------------------------------------------------
4. login
------------------------------------------------------------------------- */
.login{
  width:100vw;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container{
  margin:10px;
}
.login-text{
  font-size: 20px;
  margin-bottom: 20px;
}
.login-input{
  line-height: 2;
  font-size: 20px;
  padding-left: 5px;
}
.login-submit{
  color:white;
  background:#DCE6E6;
  margin: 10px;
  width:200px;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
}
.login-submit:hover{
  cursor: pointer;
}
/* -------------------------------------------------------------------------
5. Code
------------------------------------------------------------------------- */
.code-container{
  font-family: monospace;
  background-color: #000;
  width: em(440);
  margin: 4em auto;
  padding: 1em;
  border-radius: .25em;
  box-shadow: .1em .1em .5em rgba(0,0,0,.45);
  line-height: 0;
  counter-reset: line;
  overflow: auto;
}
.code-line{
  display: block;
  line-height: 1.5;
  color:#fff;
  font-size: 16px;
  margin-right:20px; 
}
.code-line2{
  display: block;
  line-height: 1.5;
  color:#fff;
  font-size: 16px;
  margin-right:20px; 
}
.code-line::before{
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #ddd;
  padding: 0 .5em;
  margin-right: .5em;
  color: #fff
}
.code-command{
  color:blue
}
.code-url{
  color:greenyellow
}
.code-var{
  color:palevioletred
}
.code-import{
  color: 	#19a8ff
}
.code-comment{
  color: rgba(255,255,255,0.3)
}

